import React from 'react';
import { withTranslation } from 'react-i18next';
import './Dashboard.scss';
import { AuthContext, AuthSession } from '../../contexts/AuthContext';
import Card from '../../components/Card/Card';
import PartnerBloc from '../../components/PartnerBloc/PartnerBloc';
import { loader } from '../../components/Loader/LoaderManager';
import Title from '../../components/Title/Title';
import { Button, ButtonType, toast, Icon, IconType } from '@om/design-system';
import ActionCard from '../../components/ActionCard/ActionCard';
import { getEshop, getTicketing, isWebview } from '../../utils';
import { Membership_Type } from '../../models/Membership';
import Carousel from '../../components/Carousel/Carousel';

class Dashboard extends React.Component<any> {
  state = {
    alerts: []
  }

  constructor(props: any) {
    super(props);
    this.state = {
      alerts: []
    };
  } 

  getMembershipCard() {
    return new Promise((resolve, reject) => {
      const { token, user } = this.context as AuthSession;

      if (!user?.membership?.id) {
        return;
      }

      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-token': token! }
      };

      // add parameter id for varying request and bypassing CDN
      fetch(`/api/wallet?id=${user?.membership?.id}`, options)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch((err: any) => reject(err));
    });
  }

  clickWallet = () => {
    loader.show();

    const { user } = this.context as AuthSession;
    const { t } = this.props;

    if (!user?.membership?.id) {
      loader.destroy();
      toast.show({ content: t('membership.wallet_error'), isError: true });
      return;
    }

    this.getMembershipCard()
      .then((json: any) => {
        const url = json.url;
        if (!url) {
          loader.destroy();
          toast.show({ content: t('membership.wallet_error'), isError: true });
          return;
        }

        // window open does not work on webviews
        if (isWebview()) {
          window.location.href = url;
        } else {
          window.open(url, '_blank');
        }

        loader.destroy();
      })
      .catch((err: any) => {
        loader.destroy();
        toast.show({ content: t('membership.wallet_error'), isError: true });
      })
      ;
  }

  render() {
    const { t } = this.props;
    const { user } = this.context as any;

    if (!user) { return <></> }

    return <>
      <div className='dashboard-header'>
        <div className='name-and-member desktop'>
          <Title>
            <div className='primary'>{user.firstName}</div>
            <div>{user.lastName}</div>
          </Title>
        </div>

        <div className='member-time'>
          <div className='first-letter-uppercase'>{t('dashboard.omMember', { date: user.createdAt.toLocaleDateString("fr") })}</div>
        </div>
      </div>

      <div className='dashboard'>
        {/* Member Card */}
        <div className='card item'>
          <Card membership={user.membership} />
          <div className='cta'>
            {user.membership ? <>
              <div className='subheader desktop'>{t('membership.qr_info')}</div>
              <Button type={ButtonType.Primary} onClick={this.clickWallet}><Icon type={IconType.Wallet} fill='white' /> {t('membership.button_wallet')}</Button>
              <Button type={ButtonType.Secondary} href='/account/member'>{t('membership.button_advantages')}</Button>
            </> : <>
              <Button type={ButtonType.Secondary} href='https://membres.om.fr'>{t('membership.button')}</Button>
            </>}
          </div>
        </div>

        {/* Shortcuts */}
        <div className='bloc'>
          <div className="grid-two-columns">
            {/* Shop */}
            <ActionCard goTo={getEshop()} ctaName={t('dashboard.shop_cta')} title={t('dashboard.shop_title')} icon="🛒">
              {user.membership && user.membership.type !== Membership_Type.membership_virage &&
                <div className='desktop'>{t('dashboard.shop_member_info')}</div>
              }
            </ActionCard>

            {/* Resale */}
            {user.membership && user.membership.type !== Membership_Type.membership_virage ? <>
              <ActionCard goTo={`${getTicketing()}/fr/account/tickets`} ctaName={t('membership.resale_cta')} title={t('membership.resale_title')} icon="🎟️">
                <div className='desktop'>{t('membership.resale_subtitle')}</div>
              </ActionCard>
            </> : <>
              <div className='desktop'></div>
            </>}

            {/* OM Stadium Tour */}
            <ActionCard goTo={"https://www.orangevelodrome.com/om-tour"} ctaName={t('dashboard.om_tour_cta')} title={t('dashboard.om_tour_title')} icon="🏆">
              {user.membership && user.membership.type !== Membership_Type.membership_virage &&
                <div className='desktop'>{t('dashboard.om_tour_description')}</div>
              }
            </ActionCard>

            {/* Match alerts */}
            {/*
            <ActionCard goTo={"/alerts"} ctaName={t('dashboard.match_alert')} title={t('dashboard.match_alert_cta')} icon="🔔" isNewTab={false} >
            </ActionCard>
            */}
          </div>
        </div>

        {/* Partners */}
        <div className='bloc bloc-partners'>
          <div className='title-header'>{t('dashboard.for_you')}</div>
          <Carousel>
            {user.isUnderEighteen() &&
              <PartnerBloc
                link={"https://www.fdj.fr/inscription/parions-sport-enligne/"}
                header={<img src={'https://www.om.fr/sites/default/files/2024-03/logo-psel-1l-2024.png'} alt={'Parions Sport'} />}
                children={t('dashboard.partner.parionssport')}
              />
            }
              <a href={user.membership ? '/account/member' : 'https://membres.om.fr'} target={!user.membership ? '_blank' : ''}>
                <div className='card membership-advantages'>
                  {user.membership ? t('membership.see_advantages') : t('membership.become_member')}
                </div>
              </a>
          </Carousel>
        </div>

      {/* Match alerts */}
      {/* TODO */}
      {/* <div className='bloc'>
        <div className='title-header'>{t('dashboard.match_alert')}</div>
        <div className='match-alerts'>
          <MatchList alerts={alerts} isSmall={true} />
        </div>
      </div> */}
      </div>
    </>;
  }
}

Dashboard.contextType = AuthContext;
export default withTranslation()(Dashboard);
