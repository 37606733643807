import React from 'react';
import { withTranslation } from 'react-i18next';
import Checkbox from '../../components/Checkbox';
import { Button, ButtonType, TextField, toast } from '@om/design-system';
import { AuthContext } from '../../contexts/AuthContext';
import { ConsentName } from '../../models/Consent';
import withRouter from '../../withRouter';

const ID_FORM = "prefs-form";

class PreferenceForm extends React.Component<any> {

    state = {
        errorMsg: null
    }

    send_data_with_api = (formData: any, user: any) => {
        const {id} = this.props.params;
        const date = new Date().toISOString
        const data = {
            id: user?.id || id,
            email: formData.email || user?.email,
            date,
            news: formData.news,
            ticketing: formData.ticketing,
            eshop: formData.eshop,
            partners: formData.partners,
        }
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };
        fetch('/api/preferences', options)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                toast.show({content: this.props.t('form.success'), isError: false});
            })
            .catch(error => {
                toast.show({content: this.props.t('form.error'), isError: true});
                console.error('There has been a problem with your fetch operation:', error);
            });
    }

    send_data_with_sdk = (formData: any, user: any, token: any) => {
        const date = new Date().toISOString();
        const data = {
            consents: {
                "om_actu_club": {
                    "consent_type": "opt-in",
                    "granted": formData.news,
                    "date": date
                },
                "om_billetterie": {
                    "consent_type": "opt-in",
                    "granted": formData.ticketing,
                    "date": date
                },
                "om_boutique": {
                    "consent_type": "opt-in",
                    "granted": formData.eshop,
                    "date": date
                },
                "om_partenaires": {
                    "consent_type": "opt-in",
                    "granted": formData.partners,
                    "date": date
                },
                "om_fondation": {
                    "consent_type": "opt-in",
                    "granted": formData.fondation,
                    "date": date
                }
            }
        };
        
        window.r5.updateProfile({accessToken: token, data})
            .then(() => {
                toast.show({content: this.props.t('form.success'), isError: false});
            })
            .catch((error: any) => {
                console.log(error)
                toast.show({content: this.props.t('form.error'), isError: true});
            })
    }

    submit = async (event: any) => {
        event.preventDefault();

        const form = document.getElementById(ID_FORM) as HTMLFormElement;
        const formInfo = new FormData(form);
        const formData = {
            email: formInfo.get("email"),
            news: formInfo.get("news") === "on",
            ticketing: formInfo.get("ticketing") === "on",
            eshop: formInfo.get("eshop") === "on",
            partners: formInfo.get("partners") === "on",
            fondation: formInfo.get("fondation") === "on",
            optout: formInfo.get("optout") === "on",
        };
        const {t, user: props_user} = this.props;
        const {user: context_user, token} = this.context as any;
        const user = props_user || context_user;
        const email = formData.email || user?.email;
        const {id} = this.props.params;

        const date = new Date().toISOString();

        // If not connected and email is empty
        if (!user && !email) {
            toast.show({content: t('form.error'), isError: true});
            return;
        }

        if (!user || !user.email) {
            // Not connected, use API
            this.send_data_with_api(formData, user);

        } else {
            // Connected, use SDK
            this.send_data_with_sdk(formData, user, token);
        }
    };

    optout = (event: any) => {
        var form = document.getElementById(ID_FORM) as HTMLFormElement;
        var formData = new FormData(form);
        const current = !!formData.get("optout");
        const optout = !current;

        (document.getElementById('optout') as any).checked = optout;
        (document.getElementById('news') as any).checked = !optout;
        (document.getElementById('ticketing') as any).checked = !optout;
        (document.getElementById('eshop') as any).checked = !optout;
        (document.getElementById('partners') as any).checked = !optout;
        (document.getElementById('fondation') as any).checked = !optout;
    }

    optin = () => {
        (document.getElementById('optout') as any).checked = false;
    }

    render() {
        const { t, user: props_user } = this.props;
        const { user: context_user } = this.context as any;
        const user = props_user || context_user;

        const consents = user?.getConsentsAsDict() ?? [];

        const news = ConsentName.news in consents ? consents[ConsentName.news]?.granted : true;
        const ticketing =  ConsentName.ticketing in consents ? consents[ConsentName.ticketing]?.granted : true;
        const eshop =  ConsentName.eshop in consents ? consents[ConsentName.eshop]?.granted : true;
        const partners = ConsentName.partners in consents ? consents[ConsentName.partners]?.granted : true;        
        const fondation = ConsentName.fondation in consents ? consents[ConsentName.fondation]?.granted : true;        

        const optout = !news && !ticketing && !eshop && !partners && !fondation;

        console.log('debug');
        
        return <div className="left-content-size">
            <form id={ID_FORM} onSubmit={this.submit} className='form preference-form'>
                {/* Add email in form if needed */}
                {(!user || !user.email) && 
                    <TextField name="email" type="email" placeholder={t('profile.email')} required />
                }

                <span className="row">
                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.news.title")}</div>
                        <label className='variant'>{t("prefs.news.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="news" defaultChecked={news} onChange={this.optin} /></div>
                    <div className="w-100"></div>
                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.ticketing.title")}</div>
                        <label className='variant'>{t("prefs.ticketing.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="ticketing" defaultChecked={ticketing} onChange={this.optin} /></div>
                    <div className="w-100"></div>

                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.eshop.title")}</div>
                        <label className='variant'>{t("prefs.eshop.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="eshop" defaultChecked={eshop} onChange={this.optin} /></div>
                    <div className="w-100"></div>

                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.partner.title")}</div>
                        <label className='variant'>{t("prefs.partner.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="partners" defaultChecked={partners} onChange={this.optin} /></div>

                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.fondation.title")}</div>
                        <label className='variant'>{t("prefs.fondation.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="fondation" defaultChecked={fondation} onChange={this.optin} /></div>
                </span>
                <div className="w-100"></div>
                <div className="fade show alert alert-secondary pointer" role="alert" onClick={this.optout}>
                    <div className="row">
                        <div className="col transform02"><Checkbox id="optout" defaultChecked={optout} /></div>
                        <label className='col-10 variant pointer'>{t("prefs.optout.description")}</label>
                    </div>
                </div>
                <Button type={ButtonType.Submit}>{t('save')}</Button>
            </form>
        </div>
    }
}

PreferenceForm.contextType = AuthContext;
export default withTranslation()(withRouter(PreferenceForm));