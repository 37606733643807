import matchlist from "./MatchList.module.scss";
import Switch from '../../components/Switch';
import { getOMFR } from '../../utils';
import User from "../../models/User";
import { Button } from '@om/design-system';

interface MatchListProps {
  alerts: {
    images?: any,
    title: string,
    description: string,
    splio_event_id: string,
    date: string,
    match: {
      venue: string,
      away: {
        logo: string
      },
      competition: {
        name: string
      }
    },
  }[],
  isSmall?: boolean,
  onSwitch?: (props: any) => void,
  onClick?: (props: any) => void,
  user: User,
}

export const MatchList = (props: MatchListProps) => {
  const { alerts, isSmall = false, onSwitch, onClick, user } = props;
  const r5_alerts = user?.alerts || [];

  return (
    <div className={`${matchlist.list_matches} card`}>
      { alerts.map(alert => (
        <div key={alert.title} className={`${matchlist.list_row}`}>
          <div className={`${matchlist.content}`}>
            <div className={isSmall ? `${matchlist.small} ${matchlist.match_infos}` : `${matchlist.match_infos}`}>
              <img src={getOMFR() + alert.match.away.logo} alt="" role="presentation" />
              <div className={`${matchlist.sub_infos}`}>
                <div className={`${matchlist.title}`}>{alert.description}</div>
                <div className={`${matchlist.date}`}>{new Date(alert.date).toLocaleDateString('fr-FR')}</div>
                <div className={`${matchlist.competition_location}`}>
                  <div className={`${matchlist.competition}`}>{alert.match.competition.name}</div>
                  <div className={`${matchlist.competition}`}>{alert.match.venue}</div>
                </div>
              </div>
            </div>
          </div>

          {!user ? <>
          {/* User not logged: Form */}
            <Switch 
              id={alert.splio_event_id}
              name="match"
              onSwitch={onSwitch}
              defaultChecked={r5_alerts?.includes(alert.splio_event_id)}
              disabled={r5_alerts?.includes(alert.splio_event_id)}
            />
          </> : <>
          {/* User logged in: button or active state */}
            {r5_alerts?.includes(alert.splio_event_id) ? <>
              <div className={`${matchlist.active_alert}`}>
                <span>Activée ✓</span>
              </div>
            </> : <>
              <Button id={alert.splio_event_id} onClick={onClick} className={`${matchlist.unit_button}`}>M'alerter</Button>
            </>}
          </>}
        </div>
      ))}
    </div>
  )
}