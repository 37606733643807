import React from 'react';
import { withTranslation } from 'react-i18next';
import './ActionCard.scss';
import { AuthContext } from '../../contexts/AuthContext';
import { Button, ButtonType } from '@om/design-system';

class ActionCard extends React.Component<any> {
  render() {
    const { children, goTo, ctaName, title, icon, isNewTab = true } = this.props;

    return <>
      <div className='action-card' onClick={(e) => { e.preventDefault(); window.open(goTo, isNewTab ? '_blank' : '_self', 'noreferrer')}}>
        <div className='card bloc'>
          <div className='mobile icon'>{ icon }</div>
          <div className='desktop title'>
            <span>{ icon }</span>
            <span>{ title }</span>
          </div>
          <div className='mobile cta-title first-letter-uppercase'>{ ctaName }</div>
          <div className='first-letter-uppercase subheader desktop'>{ children }</div>
          <Button type={ButtonType.Secondary} className='desktop' href={ goTo }>{ ctaName }</Button>
        </div>
      </div>
    </>;
  }
}

ActionCard.contextType = AuthContext;
export default withTranslation()(ActionCard);