import User from "./models/User";

export const getRedirectUri = () => {
  const referrer = getCookie("referrer");
  deleteCookie("referrer");
  return (new URLSearchParams(window.location.search).get('redirectUri')) || referrer || getRedirect();
}
export const deleteReferrer = () => { fetch('/api/referrer', { method: 'DELETE' }) }

export const auth = async () => {
  return new Promise((resolve, reject) => {
    window.r5.checkSession()
      .then((session: any) => {
        window.r5.getUser({ accessToken: session.accessToken, fields: 'id,email,givenName,familyName,birthdate,gender,created_at,consents,phoneNumber,addresses,emailVerified,phoneNumberVerified,custom_fields.dt_customer_id,custom_fields.populations,custom_fields.dt_customer_id,custom_fields.delete_account_date,custom_fields.is_admin,custom_fields.is_partner,lite_only,custom_fields.ticketing_alerts' })
          .then((result: any) => {
            const user = User.mapFromReachfive(result);
            if (!user.id) {
              return;
            }
            resolve({ token: session.accessToken, user });
            return;
          })
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

export const deleteCookie = (cname: string) => {
  document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export const setCookie = (cname: string, cvalue: string) => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + 1000 * 300;//5 minutes
  now.setTime(expireTime);
  document.cookie = `${cname}=${cvalue};expires=${now.toUTCString()}+';path=/`;
}

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const redirectNotFound = () => { window.location.href = "/not-found" }

export const getOMFR = () => process.env.REACT_APP_OMFR_URL;
export const getTicketing = () => process.env.REACT_APP_TICKETING_URL;
export const getEshop = () => process.env.REACT_APP_ESHOP_URL;
export const getB2B = () => process.env.REACT_APP_B2B_URL;
export const getBase = () => window.location.origin;
export const getResetPassword = () => `${getBase()}/reset-password`;
export const getResetEmail = () => `${getBase()}/reset-email`;
export const getHome = () => `/account/`;
export const getLogin = () => `/login`;
export const getRedirect = (redirect?: string) => `${getBase()}/redirect`;
export const getLogout = () => "/logout";

export enum UserAgentType {
  Safari = 'Safari',
  iOS = 'iOS',
  Android = 'Android'
}

export const userAgent = () => { 
  const ua = window.navigator.userAgent.toLowerCase();

  if (/safari/.test(ua)) {
      return UserAgentType.Safari;
  }
  if (/iphone|ipod|ipad/.test(ua)) {
      return UserAgentType.iOS;
  }
  if (/wv/.test(ua)) {
      return UserAgentType.Android;
  }
  return null;
}

export const isWebview = () => {
  // @ts-ignore
  const standalone = window.navigator.standalone;
  const ua = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(ua);
  return (/iphone|ipod|ipad/.test(ua) && !standalone && !safari) || /wv/.test(ua);
}