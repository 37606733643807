// @ts-nocheck
/* eslint-disable */

let dqecomplete = function() {
    function e(e) {
        function t(e, t) {
            return e.classList ? e.classList.contains(t) : new RegExp("\\b" + t + "\\b").test(e.className)
        }

        function o(e, t, o) {
            e.attachEvent ? e.attachEvent("on" + t, o) : e.addEventListener(t, o)
        }

        function s(e, t, o) {
            e.detachEvent ? e.detachEvent("on" + t, o) : e.removeEventListener(t, o)
        }

        function n(e, s, n, l) {
            o(l || document, s, function(o) {
                for (var s, l = o.target || o.srcElement; l && !(s = t(l, e));) l = l.parentElement;
                s && n.call(l, o)
            })
        }
        if (document.querySelector) {
            var l = {
                selector: 0,
                source: 0,
                minChars: 3,
                delay: 150,
                offsetLeft: 0,
                offsetTop: 1,
                cache: 1,
                menuClass: "",
                renderItem: function(e, t) {
                    t = t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
                    var o = new RegExp("(" + t.split(" ").join("|") + ")", "gi");
                    return '<div class="dqe-suggestion" data-val="' + e + '">' + e.replace(o, "<b>$1</b>") + "</div>"
                },
                onSelect: function() {}
            };
            for (var c in e) e.hasOwnProperty(c) && (l[c] = e[c]);
            for (var a = "object" == typeof l.selector ? [l.selector] : document.querySelectorAll(l.selector), u = 0; u < a.length; u++) {
                var i = a[u];
                i.sc = document.createElement("div"), i.sc.className = "dqe-suggestions " + l.menuClass, i.autocompleteAttr = i.getAttribute("autocomplete"), i.setAttribute("autocomplete", "disabled-autofill"), i.cache = {}, i.last_val = "", i.updateSC = function(e, t) {
                    var o = i.getBoundingClientRect();
                    if (i.sc.style.left = Math.round(o.left + (window.pageXOffset || document.documentElement.scrollLeft) + l.offsetLeft) + "px", i.sc.style.top = Math.round(o.bottom + (window.pageYOffset || document.documentElement.scrollTop) + l.offsetTop) + "px", i.sc.style.width = Math.round(o.right - o.left) + "px", !e && (i.sc.style.display = "block", i.sc.maxHeight || (i.sc.maxHeight = parseInt((window.getComputedStyle ? getComputedStyle(i.sc, null) : i.sc.currentStyle).maxHeight)), i.sc.suggestionHeight || (i.sc.suggestionHeight = i.sc.querySelector(".dqe-suggestion").offsetHeight), i.sc.suggestionHeight))
                        if (t) {
                            var s = i.sc.scrollTop,
                                n = t.getBoundingClientRect().top - i.sc.getBoundingClientRect().top;
                            n + i.sc.suggestionHeight - i.sc.maxHeight > 0 ? i.sc.scrollTop = n + i.sc.suggestionHeight + s - i.sc.maxHeight : 0 > n && (i.sc.scrollTop = n + s)
                        } else i.sc.scrollTop = 0
                }, o(window, "resize", i.updateSC), document.body.appendChild(i.sc), n("dqe-suggestion", "mouseleave", function() {
                    var e = i.sc.querySelector(".dqe-suggestion.selected");
                    e && setTimeout(function() {
                        e.className = e.className.replace("selected", "")
                    }, 20)
                }, i.sc), n("dqe-suggestion", "mouseover", function() {
                    var e = i.sc.querySelector(".dqe-suggestion.selected");
                    e && (e.className = e.className.replace("selected", "")), this.className += " selected"
                }, i.sc), n("dqe-suggestion", "mousedown", function(e) {
                    if (t(this, "dqe-suggestion")) {
                        var o = this.getAttribute("data-val");
                        i.value = o, l.onSelect(e, o, this), i.sc.style.display = "none"
                    }
                }, i.sc), i.blurHandler = function() {
                    try {
                        var e = document.querySelector(".dqe-suggestions:hover")
                    } catch (t) {
                        var e = 0
                    }
                    e ? i !== document.activeElement && setTimeout(function() {
                        i.focus()
                    }, 20) : (i.last_val = i.value, i.sc.style.display = "none", setTimeout(function() {
                        i.sc.style.display = "none"
                    }, 350))
                }, o(i, "blur", i.blurHandler);
                var r = function(e) {
                    var t = i.value;
                    if (i.cache[t] = e, e.length && t.length >= l.minChars) {
                        for (var o = "", s = 0; s < e.length; s++) o += l.renderItem(e[s], t);
                        i.sc.innerHTML = o, i.updateSC(0)
                    } else i.sc.style.display = "none"
                };
                i.keydownHandler = function(e) {
                    var t = window.event ? e.keyCode : e.which;
                    if ((40 == t || 38 == t) && i.sc.innerHTML) {
                        var o, s = i.sc.querySelector(".dqe-suggestion.selected");
                        return s ? (o = 40 == t ? s.nextSibling : s.previousSibling, o ? (s.className = s.className.replace("selected", ""), o.className += " selected", i.value = o.getAttribute("data-val")) : (s.className = s.className.replace("selected", ""), i.value = i.last_val, o = 0)) : (o = 40 == t ? i.sc.querySelector(".dqe-suggestion") : i.sc.childNodes[i.sc.childNodes.length - 1], o.className += " selected", i.value = o.getAttribute("data-val")), i.updateSC(0, o), !1
                    }
                    if (27 == t) i.value = i.last_val, i.sc.style.display = "none";
                    else if (13 == t || 9 == t) {
                        var s = i.sc.querySelector(".dqe-suggestion.selected");
                        s && "none" != i.sc.style.display && (l.onSelect(e, s.getAttribute("data-val"), s), setTimeout(function() {
                            i.sc.style.display = "none"
                        }, 20))
                    }
                }, o(i, "keydown", i.keydownHandler), i.keyupHandler = function(e) {
                    var t = window.event ? e.keyCode : e.which;
                    if (!t || (35 > t || t > 40) && 13 != t && 27 != t) {
                        var o = i.value;
                        if (o.length >= l.minChars) {
                            if (o != i.last_val) {
                                if (i.last_val = o, clearTimeout(i.timer), l.cache) {
                                    if (o in i.cache) return void r(i.cache[o]);
                                    for (var s = 1; s < o.length - l.minChars; s++) {
                                        var n = o.slice(0, o.length - s);
                                        if (n in i.cache && !i.cache[n].length) return void r([])
                                    }
                                }
                                i.timer = setTimeout(function() {
                                    l.source(o, r)
                                }, l.delay)
                            }
                        } else i.last_val = o, i.sc.style.display = "none"
                    }
                }, o(i, "keyup", i.keyupHandler), i.focusHandler = function(e) {
                    i.last_val = "\n", i.keyupHandler(e)
                }, l.minChars || o(i, "focus", i.focusHandler)
            }
            this.destroy = function() {
                for (var e = 0; e < a.length; e++) {
                    var t = a[e];
                    s(window, "resize", t.updateSC), s(t, "blur", t.blurHandler), s(t, "focus", t.focusHandler), s(t, "keydown", t.keydownHandler), s(t, "keyup", t.keyupHandler), t.autocompleteAttr ? t.setAttribute("autocomplete", t.autocompleteAttr) : t.removeAttribute("autocomplete"), document.body.removeChild(t.sc), t = null
                }
            }
        }
    }
    return e
}();
! function() {
    "function" == typeof define && define.amd ? define("dqecomplete", function() {
        return dqecomplete
    }) : "undefined" != typeof module && module.exports ? module.exports = dqecomplete : window.dqecomplete = dqecomplete
}();

export default class dqe_single {

    constructor(options) {
        //Liste des pays pour lesquels le numéro est situé après le nom de la voie
        this.reversed_countries = {
            AUT: 1, BEL: 1, CHE: 1, CZE: 1, DEU: 1, ESP: 1, HRV: 1, ISR: 1, ITA: 1, NLD: 1, POL: 1, PRT: 1,
            ROU: 1, SWE: 1, TUR: 1, RUS: 1, SVN: 1, DNK: 1, FIN: 1, HUN: 1, NOR: 1
        };

        //Expressions régulières permettant de tester si un code postal a été complètement saisi pour lancer la recherche de villes
        this.zip_pattern = {
            'FRA': /^\d{5}$/,
            'DZA': /^\d{5}$/,
            'DEU': /^\d{3,5}$/,
            'AUT': /^\d{4}$/,
            'TUN': /^\d{4}$/,
            'AUS': /^\d{4}$/,
            'BEL': /^\d{4}$/,
            'BRA': /^\d{5}[ -]?\d{3}$/,
            'CAN': /^[A-Z\d]{3} ?[A-Z\d]{3}$/,
            'KOR': /^\d{3,5}$/,
            'ESP': /^\d{5}$/,
            'USA': /^([A-Z]{2})?[ -]?\d{5}[ -]?(\d{4})?$/i,
            'ISR': /^\d{5}$/,
            'ITA': /^\d{5}$/,
            'JPN': /^\d{3}[ -]?\d{0,4}$/,
            'JPN-en': /^\d{3}[ -]?\d{0,4}$/,
            'LUX': /^L?-?\d{4}$/i,
            'NLD': /.{3,}$/,
            'POL': /^\d\d-?\d{3}$/,
            'PRT': /^\d{4}-?\d{3}$/,
            'CZE': /^\d{3} ?\d{2}$/,
            'ROU': /^\d{6}$/,
            'GBR': /^[A-Z][A-Z\d]{1,3} ?\d[A-Z]{2}$/i,
            'SGP': /^\d{3,6}$/,
            'SVN': /^\d{4}$/,
            'SWE': /^(SE)?-?\d{3} ?\d{2}$/,
            'CHE': /^\d{4}$/,
            'TUR': /^\d{5}$/,
            'CHN': /^\d{6}$/,
            'CHN-en': /^\d{6}$/,
            'RUS': /^\d{6}$/,
            'NOR': /^\d{4}$/,
            'DNK': /^\d{4}$/,
            'FIN': /^\d{3,5}$/,
            'THA': /^\d{5}$/,
            'MAR': /^\d{5}$/,
            'HUN': /^\d{4}$/,
            'HKG': /^.*$/, //Hong-kong
            'HKG-en': /^.*$/, //Hong-kong
            'QAT': /^.*$/, //Qatar
            'NZL': /^\d{4}$/, //Nouvelle-zélande
            'HRV': /^(HR)?-?\d{5}$/ //Croatie
        };

        //On récupère les champs à partir de leur selecteur
        this.server    = options.server;
        this.license   = options.license || ''; //obligatoire si appel direct via cors

        //champs autocomplétés
        this.city        = options.city        ? document.querySelector(options.city)        : false;
        this.zip         = options.zip         ? document.querySelector(options.zip)         : false;
        this.street      = options.street      ? document.querySelector(options.street)      : false;
        this.street_type = options.street_type ? document.querySelector(options.street_type) : false;
        this.number      = options.number      ? document.querySelector(options.number)      : false;
        this.compl       = options.compl       ? document.querySelector(options.compl)       : false;
        this.local       = options.local       ? document.querySelector(options.local)       : false; //Lieu-dit
        this.prov        = options.prov        ? document.querySelector(options.prov)        : false; //Province
        this.company     = options.company     ? document.querySelector(options.company)     : false; //Nom de la société
        this.insee       = options.insee       ? document.querySelector(options.insee)       : false; //code insee
        this.cedex       = !!options.cedex;  //Recherche avec ou sans cedex

        //options de géoloc
        this.geoloc       = options.geoloc || false; //Fonction à appeler avec les coordonnées de l'emplacement
        this.nearest      = options.nearest || false; //Fonction à appeler avec les coordonnées de l'emplacement
        this.city_search  = options.city_search ? 1 : 0;
        this.min_bound    = options.min_bound || -1;
        this.max_bound    = options.max_bound || -1;
        this.force_nums   = options.force_nums || {};
        this.taille       = options.taille || false;
        this.countryField = options.country.length === 3 ? false : document.querySelector(options.country);
        this.country      = options.country || "FRA";
        this.save("cityid", "");

        this.lat = 0;
        this.lon = 0;
        this.numlist = [];
        
        this.valid_zip = zip => {
            let country = this.selected_country();
            if (!this.zip_pattern[country]) return true;
            if (!zip.match(/^\d/)) return true;
            return zip.match(this.zip_pattern[country]);
        };
        
        this.zip.addEventListener("input", () => {
            this.save("cityid", "");
        });
        
        this.clear = () => {
            this.save("cityid", "");
            if (this.city) this.city.value = "";
            if (this.zip) this.zip.value = "";
            if (this.street) this.street.value = "";
            if (this.street_type) this.street_type.value = "";
            if (this.number) this.number.value = "";
            if (this.compl) this.compl.value = "";
            if (this.local) this.local.value = "";
            if (this.prov) {
                if (this.prov.value === undefined) this.prov.innerHTML = '';
                else this.prov.value = "";
            }
            if (this.company) this.company.value = "";
            if (this.insee) this.insee.value = "";
            this.lat = 0;
            this.lon = 0;
            this.numlist = [];
            this.save("currnum", "");
            this.save("currsid", "");
            this.current_term = "";
            this.current_locality = "";
            this.input = "";
            this.save("filling_compl", "");
        }
        
        this.street.addEventListener("keyup", () => {
            if (this.numlist.length) this.numlist = [];
        });
        
        this.destroy = () => {
            this.ac_street.destroy();
            this.ac_zip.destroy();
            this.ac_compl.destroy();
        }

        this.ac_street = new dqecomplete({
            selector: options.street,
            minChars: 0,
            delay: 0,
            cache: 0,
            source: (s, callback) => {
                if (this.selecting) return;
                this.nia = this.num_is_after();
                this.input = s;
                if (this.numlist.length) return callback(this.numlist);
                if (s.length < 2 || s === this.current_term) return callback([]);
                this.save("currsid", "");
                this.save("currnum", "");

                let xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    if (xhr.status === 200) callback(this.parse_items(xhr.responseText));
                };
                
                let url;
                const cid = this.load("cityid");
                if (cid) url = this.url("ADR", `IDLocalite=${cid}&Adresse=${encodeURIComponent(s)}`);
                else url = this.url("SINGLEV2", `Adresse=${encodeURIComponent(s)}`);
                if (this.server) {
                    xhr.open("POST", this.server);
                    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                    xhr.send(`url=${encodeURIComponent(url)}`);
                }
                else {
                    xhr.open("GET", url);
                    xhr.send();
                }
            },
            renderItem: el => {
                this.current_term = "";
                const street = this.recombine_street(el.Num, "", el.Voie, this.nia);
                const locality = el.LieuDit ? el.LieuDit : '';
                let label = this.reformat_label(el.label ? el.label : street, this.input);
                if (locality && label.indexOf(locality) === -1) label += ` (${locality})`;
                let prov = [];
                if (el.Province && el.Province !== el.Localite) prov.push(el.Province);
                if (el.Region1 && el.Region1 !== el.Localite && el.Region1 !== el.Province) prov.push(el.Region1);
                if (el.Region2  && el.Region2 !== el.Localite && el.Region2 !== el.Province && el.Region2 !== el.Region1) prov.push(el.Region2);
                prov = prov.length > 0 ? prov.join(", ") : '';
                return `<div data-street="${el.Voie || ''}" data-prov="${prov}" data-nums="${el.ListeNumero || ''}" data-local="${locality}" data-num="${el.Num || ''}" data-streetid="${el.CodeVoie || ''}" data-insee="${el.IDLocalite}" class="dqe-suggestion" data-company="${el.Entreprise || ''}" data-val="${street}" data-zip="${el.CodePostal || ''}" data-city="${el.Localite || ''}" data-lat="${el.Latitude || ''}" data-lon="${el.Longitude || ''}" data-compl="${el.Complement || ''}">${label}</div>`;
            },
            onSelect: (e, term, item) => {
                this.selecting = true;
                this.current_term = term;
                const raw_city = item.getAttribute("data-city");
                let city = raw_city;
                let locality = item.getAttribute("data-local");
                if (this.local) this.local.value = locality ? locality : "";
                if (locality && city.indexOf("locality") === -1 && !this.local) {
                    if (locality.indexOf("(") > -1) city = `${locality}, ${city}`;
                    else city += ` (${locality})`;
                }

                if (this.company) this.company.value = item.getAttribute("data-company");
                if (this.compl) this.compl.value = item.getAttribute("data-compl");
                if (this.insee) this.insee.value = item.getAttribute("data-insee");
                let zipcode = item.getAttribute("data-zip");
                this.zip.value = zipcode;
                this.city.value = city;
                this.save("currnum", item.getAttribute("data-num"));
                this.save("currsid", item.getAttribute("data-streetid"));
                this.current_locality = locality;
                
                const region = item.getAttribute("data-prov");
                if (this.prov && region) {
                    if (this.prov.value === undefined) this.prov.innerHTML = region;
                    else this.prov.value = region;
                }

                let numlist = item.getAttribute("data-nums");
                let streetname = item.getAttribute("data-street");
                
                setTimeout(() => this.selecting = false, 300);

                if (numlist && this.load("currnum") !== numlist) {
                    this.selecting = false;
                    this.save("currnum", "");
                    let el, num, lbl;
                    numlist = numlist.split(";");
                    for (let i = 0, len = numlist.length; i < len; i++) {
                        num = numlist[i];
                        lbl = this.recombine_street(num, "", streetname, this.nia);
                        
                        el = {
                            ListeNumero: "",
                            Num: num,
                            CodeVoie: this.load("currsid"),
                            Voie: streetname,
                            IDLocalite: this.insee,
                            Entreprise: this.company.value,
                            CodePostal: zipcode,
                            Localite: raw_city,
                            Latitude: item.getAttribute("data-lat"),
                            Longitude: item.getAttribute("data-lon"),
                            Complement: this.compl.value,
                            label: lbl,
                            Saisie: lbl,
                            LieuDit: this.current_locality,
                        };
                        this.numlist.push(el);
                    }

                    this.street.blur();
                    this.street.focus();
                    this.street.dispatchEvent(new Event('keydown'));
                    return;
                }
                else this.numlist = [];

                if (this.compl) {
                    this.numlist = [];
                    this.compl.blur();
                    this.compl.focus();
                    this.compl.dispatchEvent(new Event('keydown'));
                    this.save("filling_compl", "");
                }

                //Dispatch event "selected" with all data
                const params = {}, attrs = item.attributes;
                for(let i = 0, param, len = attrs.length; i < len; ++i) {
                    param = attrs[i];
                    if (param.name.startsWith("data-") && param.value) params[param.name.slice(5)] = param.value;
                }
                this.street.dispatchEvent(new CustomEvent('selected', {detail: params}));
            },
        });
        
        if (this.zip) {
            this.ac_zip = new dqecomplete({
                selector: options.zip,
                minChars: 3,
                delay: 0,
                cache: 0,
                source: (s, callback) => {
                    this.save("cityid", "");
                    if (s.length < 3 || !this.valid_zip(s)) return callback([]);
                    let xhr = new XMLHttpRequest();
                    xhr.onload = () => {
                        if (xhr.status === 200) callback(this.parse_items(xhr.responseText));
                    };

                    let url = this.url("CP", `CodePostal=${encodeURIComponent(s)}`);
                    if (this.server) {
                        xhr.open("POST", this.server);
                        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                        xhr.send(`url=${encodeURIComponent(url)}`);
                    }
                    else {
                        xhr.open("GET", url);
                        xhr.send();
                    }
                },
                renderItem: (el) => {
                    this.current_term = "";
                    const ld = el.LieuDit ? el.LieuDit : '';
                    const compl = el.Complement ? el.Complement : "";
                    const zip = el.CodePostal ? el.CodePostal : "";
                    const city = el.Localite ? el.Localite : "";
                    let label = `${zip} ${city}`;
                    if (compl) label += ` (${compl})`;
                    if (ld) label += `<br/><span style="color:#080">${ld}</span>`;
                    return `<div data-ld="${ld}" data-cityid="${el.IDLocalite}" class="dqe-suggestion" data-val="${zip}" data-city="${city}" data-compl="${compl}">${label}</div>`;
                },
                onSelect: (e, term, item) => {
                    this.save("cityid", item.getAttribute("data-cityid"));
                    if (this.compl) this.compl.value = item.getAttribute("data-compl");
                    if (this.zip) this.zip.value = item.getAttribute("data-val");

                    const ld = item.getAttribute("data-ld");
                    let city = item.getAttribute("data-city");
                    if (this.local) this.local.value = ld;
                    else city += ` (${ld})`;
                    if (this.city) this.city.value = city;
                    this.street.value = "";
                    this.street.focus();
                }
            });
        }

        if (this.compl) {
            this.compl.addEventListener("blur", () => {
                setTimeout(() => this.save("filling_compl", ""), 200);
            });
            this.compl.addEventListener("keyup", () => this.save("filling_compl", ""));
            
            this.ac_compl = new dqecomplete({
                selector: options.compl,
                minChars: 0,
                delay: 0,
                cache: 0,
                source: (s, callback) => {
                    this.nia = this.num_is_after();
                    if (this.load("filling_compl") === 'yes') return;
                    this.input = s;
                    this.save("filling_compl", "yes");

                    const xhr = new XMLHttpRequest();
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            const elts = this.parse_items(xhr.responseText);
                            callback(elts);
                        }
                        else callback([]);
                    };

                    const filter = s.length > 0 ? `&Filter=${encodeURIComponent(s)}` : '';
                    const streetid = encodeURIComponent(this.load("currsid"));
                    const currnum = encodeURIComponent(this.load("currnum"));
                    const url = this.url("COMPLV2", `IDVoie=${streetid}&IDNum=${currnum}${filter}&NbResponse=200`);

                    if (this.server) {
                        xhr.open("POST", this.server);
                        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                        xhr.send(`url=${encodeURIComponent(url)}`);
                    }
                    else {
                        xhr.open("GET", url);
                        xhr.send();
                    }
                },
                renderItem: el => {
                    this.current_term = "";
                    let zip = el.CodePostal ? el.CodePostal : "";
                    return `<div class="dqe-suggestion" data-zip="${zip}" data-val="${el.Batiment}">${el.Batiment}</div>`;
                },
                onSelect: (e, term, item) => {
                    this.save("filling_compl", "yes");
                    let compl_zip = item.getAttribute("data-zip");
                    if (compl_zip) this.zip.value = compl_zip;
                    this.compl.blur();
                    setTimeout(() => {
                        this.save("filling_compl", "");
                        this.focus_next_field(this.compl);
                    }, 200);
                }
            });
        }
    }

    focus_next_field(el) {
        let fields = el.closest("form").querySelectorAll("input, select, textarea");
        fields = Array.from(fields);
        const i = fields.indexOf(el);
        if (i < fields.length - 1) fields[i + 1].focus();
    }

    parse_items(text) {
        if (!text) return [];
        let dict = JSON.parse(text);
        let result = [];
        for (let i = 1; dict[i]; ++i) result.push(dict[i]);
        return result;
    }

    remove_accents(s) {
        let ko = 'ÀÁÂÃÄÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÇçàáâãèéêëîïòôõöùúûü';
        let ok = 'AAAAAEEEEIIIIOOOOOUUUUCcaaaaeeeeiioooouuuu';
        let p, c, result = "";
        for (let i = 0, len = s.length; i < len; i++) {
            c = s.charAt(i);
            p = ko.indexOf(c);
            result += p === -1 ? c : ok.charAt(p);
        }
        return result;
    }

    url(fn, payload) {
        const country = this.selected_country();
        const licence = encodeURIComponent(this.license);
        return `https://prod2.dqe-software.com/${fn}/?${payload}&Pays=${country}&Licence=${licence}`;
    }

    //Vérifie si la saisie comporte des numéros
    has_number(street) {
        street = street.trim();
        if (!street) return false;
        let country = this.selected_country();
        let code = this.reversed_countries[country] ? street.charCodeAt(street.length - 1) : street.charCodeAt(0);
        return code >= 48 && code <= 57;
    }

    locate() {
        if (this.nearest) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(obj => {
                    let coords = obj.coords;
                    this.lat = coords.latitude ? coords.latitude : 0;
                    this.lon = coords.longitude ? coords.longitude : 0;
                    this.street.autocomplete('search');
                }, () => {
                    this.lat = 0;
                    this.lon = 0;
                }, {enableHighAccuracy: true, timeout: 10000, maximumAge: 0});
            }
        }
    }

    selected_country() {
        let country = this.countryField ? this.countryField.value : this.country;
        if (country.length === 2) return this.convert_iso2(country.toUpperCase());
        return country;
    }

    convert_iso2(country) {
        let table = {
            AF: 'AFG', AX: 'ALA', AL: 'ALB', DZ: 'DZA', AS: 'ASM', AD: 'AND', AO: 'AGO', AI: 'AIA', AQ: 'ATA', AG: 'ATG', AR: 'ARG', AM: 'ARM', AW: 'ABW',
            AU: 'AUS', AT: 'AUT', AZ: 'AZE', BS: 'BHS', BH: 'BHR', BD: 'BGD', BB: 'BRB', BY: 'BLR', BE: 'BEL', BZ: 'BLZ', BJ: 'BEN', BM: 'BMU', BT: 'BTN',
            BO: 'BOL', BA: 'BIH', BW: 'BWA', BV: 'BVT', BR: 'BRA', VG: 'VGB', IO: 'IOT', BN: 'BRN', BG: 'BGR', BF: 'BFA', BI: 'BDI', KH: 'KHM', CM: 'CMR',
            CA: 'CAN', CV: 'CPV', KY: 'CYM', CF: 'CAF', TD: 'TCD', CL: 'CHL', CN: 'CHN', HK: 'HKG', MO: 'MAC', CX: 'CXR', CC: 'CCK', CO: 'COL', KM: 'COM',
            CG: 'COG', CD: 'COD', CK: 'COK', CR: 'CRI', CI: 'CIV', HR: 'HRV', CU: 'CUB', CY: 'CYP', CZ: 'CZE', DK: 'DNK', DJ: 'DJI', DM: 'DMA', DO: 'DOM',
            EC: 'ECU', EG: 'EGY', SV: 'SLV', GQ: 'GNQ', ER: 'ERI', EE: 'EST', ET: 'ETH', FK: 'FLK', FO: 'FRO', FJ: 'FJI', FI: 'FIN', FR: 'FRA', GF: 'GUF',
            PF: 'PYF', TF: 'ATF', GA: 'GAB', GM: 'GMB', GE: 'GEO', DE: 'DEU', GH: 'GHA', GI: 'GIB', GR: 'GRC', GL: 'GRL', GD: 'GRD', GP: 'GLP', GU: 'GUM',
            GT: 'GTM', GG: 'GGY', GN: 'GIN', GW: 'GNB', GY: 'GUY', HT: 'HTI', HM: 'HMD', VA: 'VAT', HN: 'HND', HU: 'HUN', IS: 'ISL', IN: 'IND', ID: 'IDN',
            IR: 'IRN', IQ: 'IRQ', IE: 'IRL', IM: 'IMN', IL: 'ISR', IT: 'ITA', JM: 'JAM', JP: 'JPN', JE: 'JEY', JO: 'JOR', KZ: 'KAZ', KE: 'KEN', KI: 'KIR',
            KP: 'PRK', KR: 'KOR', KW: 'KWT', KG: 'KGZ', LA: 'LAO', LV: 'LVA', LB: 'LBN', LS: 'LSO', LR: 'LBR', LY: 'LBY', LI: 'LIE', LT: 'LTU', LU: 'LUX',
            MK: 'MKD', MG: 'MDG', MW: 'MWI', MY: 'MYS', MV: 'MDV', ML: 'MLI', MT: 'MLT', MH: 'MHL', MQ: 'MTQ', MR: 'MRT', MU: 'MUS', YT: 'MYT', MX: 'MEX',
            FM: 'FSM', MD: 'MDA', MC: 'MCO', MN: 'MNG', ME: 'MNE', MS: 'MSR', MA: 'MAR', MZ: 'MOZ', MM: 'MMR', NA: 'NAM', NR: 'NRU', NP: 'NPL', NL: 'NLD',
            AN: 'ANT', NC: 'NCL', NZ: 'NZL', NI: 'NIC', NE: 'NER', NG: 'NGA', NU: 'NIU', NF: 'NFK', MP: 'MNP', NO: 'NOR', OM: 'OMN', PK: 'PAK', PW: 'PLW',
            PS: 'PSE', PA: 'PAN', PG: 'PNG', PY: 'PRY', PE: 'PER', PH: 'PHL', PN: 'PCN', PL: 'POL', PT: 'PRT', PR: 'PRI', QA: 'QAT', RE: 'REU', RO: 'ROU',
            RU: 'RUS', RW: 'RWA', BL: 'BLM', SH: 'SHN', KN: 'KNA', LC: 'LCA', MF: 'MAF', PM: 'SPM', VC: 'VCT', WS: 'WSM', SM: 'SMR', ST: 'STP', SA: 'SAU',
            SN: 'SEN', RS: 'SRB', SC: 'SYC', SL: 'SLE', SG: 'SGP', SK: 'SVK', SI: 'SVN', SB: 'SLB', SO: 'SOM', ZA: 'ZAF', GS: 'SGS', SS: 'SSD', ES: 'ESP',
            LK: 'LKA', SD: 'SDN', SR: 'SUR', SJ: 'SJM', SZ: 'SWZ', SE: 'SWE', CH: 'CHE', SY: 'SYR', TW: 'TWN', TJ: 'TJK', TZ: 'TZA', TH: 'THA', TL: 'TLS',
            TG: 'TGO', TK: 'TKL', TO: 'TON', TT: 'TTO', TN: 'TUN', TR: 'TUR', TM: 'TKM', TC: 'TCA', TV: 'TUV', UG: 'UGA', UA: 'UKR', AE: 'ARE', GB: 'GBR',
            US: 'USA', UM: 'UMI', UY: 'URY', UZ: 'UZB', VU: 'VUT', VE: 'VEN', VN: 'VNM', VI: 'VIR', WF: 'WLF', EH: 'ESH', YE: 'YEM', ZM: 'ZMB', ZW: 'ZWE'
        };
        return table[country] ? table[country] : 'FRA';
    }

    num_is_after() {
        let country = this.selected_country();
        return !!this.reversed_countries[country];
    }

    recombine_street(num, street_type, street_name, nia) {
        let parts = [];
        if (nia) {
            //Le n° est placé après la rue
            if (street_type) parts.push(street_type);
            if (street_name) parts.push(street_name);
            if (num) parts.push(num);
        }
        else {
            //Le n° est placé avant la rue
            if (num) {
                if (this.hasBisOrTer(num)) {
                    let new_num = num.slice(0, -1) + ' ' + num.slice(num.length - 1);
                    parts.push(new_num);
                }
                else parts.push(num);
            }
            if (street_type) parts.push(street_type);
            if (street_name) parts.push(street_name);
        }
        return parts.join(" ");
    }

    hasBisOrTer(str) {
        let lastChar = str.slice(-1);
        let bmpDigits = /[\d\u0660-\u0669\u06F0-\u06F9\u07C0-\u07C9\u0966-\u096F\u09E6-\u09EF\u0A66-\u0AE6\u0AE6-\u0AEF\u0B66-\u0B6F\u0BE6-\u0BEF\u0C66-\u0C6F\u0CE6-\u0CEF\u0D66-\u0D6F\u0DE6-\u0DEF\u0E50-\u0E59\u0ED0-\u0ED9\u0F20-\u0F29\u1040-\u1049\u1090-\u1099\u17E0-\u17E9\u1810-\u1819\u1946-\u194F\u19D0-\u19D9\u1A80-\u1A89\u1A90-\u1A99\u1B50-\u1B59\u1BB0-\u1BB9\u1C40-\u1C49\u1C50-\u1C59\uA620-\uA629\uA8D0-\uA8D9\uA900-\uA909\uA9D0-\uA9D9\uA9F0-\uA9F9\uAA50-\uAA59\uABF0-\uABF9\uFF10-\uFF19]/;
        let hasNumber = RegExp.prototype.test.bind(bmpDigits);
        return !hasNumber(lastChar);
    }

    highlight_term(source, term) {
        if (!term || !source) return source;
        let simple_source = this.remove_accents(source).toLowerCase();
        let simple_term = this.remove_accents(term).toLowerCase();
        let result = "";
        let len = term.length;
        let pos = simple_source.indexOf(simple_term);
        while (pos > -1) {
            result += source.slice(0, pos) + '{' + source.slice(pos, pos + len) + '}';
            source = source.slice(pos + len);
            simple_source = simple_source.slice(pos + len);
            pos = simple_source.indexOf(simple_term);
        }
        if (source) result += source;
        return result;
    }

    reformat_label(label, term) {
        let res;
        if (term && term.indexOf(" ") > -1) {
            let terms = term.split(" ");
            let len = terms.length;
            res = label;
            for (let i = 0; i < len; i++) res = this.highlight_term(res, terms[i]);
        }
        else res = this.highlight_term(label, term);
        res = res.replaceAll('{', '<strong>').replaceAll('}', '</strong>');
        res = res.replaceAll('|', '<br/><span style="color:#080">') + '</span>';
        res = res.replaceAll('[', '<em>').replaceAll(']', '</em>');
        return res;
    }
    
    save(key, val) {
        this.street.setAttribute(`data-${key}`, val);
    }
    
    load(key) {
        return this.street.getAttribute(`data-${key}`);
    }
}
