import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Member.scss';
import logo from '../../assets/img/LOGO_PBB_FONCE.png';
import { AuthContext } from '../../contexts/AuthContext';
import QRCodeStyling from "qr-code-styling";
import Card from '../../components/Card/Card';
import { getEshop, getOMFR, getTicketing, isWebview } from '../../utils';
import { Button, ButtonType, Icon, IconType, toast } from '@om/design-system';
import { Membership_Type } from '../../models/Membership';
import { loader } from '../../components/Loader/LoaderManager';
import Carousel from '../../components/Carousel/Carousel';
import PartnerBloc from '../../components/PartnerBloc/PartnerBloc';

const Member = () => {
  const { t } = useTranslation();
  const { token, user } = useContext(AuthContext);
  const ref = useRef(null as any);
  const [partnerCount, setPartnerCount] = useState(undefined);
  const [clubOfferCount, setClubOfferCount] = useState(undefined);

  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    type: 'svg',
    image: user && user.membership && user.membership.type !== Membership_Type.om_legendes ? logo : '',
    dotsOptions: {
      color: "#092C4D",
      type: "rounded",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 3,
      imageSize: 0.4,
    },
    qrOptions: {
      mode: 'Numeric',
      typeNumber: 0
    },
    cornersSquareOptions: {
      type: 'extra-rounded'
    },
    cornersDotOptions: {
      type: 'dot'
    },
  });

  const getMembershipCard = () => {
    return new Promise((resolve, reject) => {
      if (!user?.membership?.id) {
        return reject(null);
      }

      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-token': token! }
      };

      // add parameter id for varying request and bypassing CDN
      fetch(`/api/wallet?id=${user?.membership?.id}`, options)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch((err: any) => reject(err));
    });
  }

  const clickWallet = () => {
    loader.show();

    if (!user?.membership?.id) {
      loader.destroy();
      toast.show({ content: t('membership.wallet_error'), isError: true });
      return;
    }

    getMembershipCard()
      .then((json: any) => {
        const url = json.url;
        if (!url) {
          loader.destroy();
          toast.show({ content: t('membership.wallet_error'), isError: true });
          return;
        }

        // window open does not work on webviews
        if (isWebview()) {
          window.location.href = url;
        } else {
          window.open(url, '_blank');
        }

        loader.destroy();
      })
      .catch((err: any) => {
        loader.destroy();
        toast.show({ content: t('membership.wallet_error'), isError: true });
        return;
      })
      ;
  }

  const getAdvantages = () => {
    return {
      'tickets': {
        'link': getTicketing(),
        'icon': IconType.Tickets,
        'children': t('membership.ticket_info'),
        'title': t('membership.ticket'),
      },
      'eshop': {
        'link': getEshop(),
        'icon': IconType.Shop,
        'children': t('membership.shop_info'),
        'title': t('membership.shop'),
      },
      'news': {
        'link': `${getOMFR()}/fr/news?type=All&field_theme_target_id=All&field_accessibility_target_id=2`,
        'icon': IconType.Camera,
        'children': t('membership.content_info'),
        'title': t('membership.content'),
      }
    };
  }

  const getPartners = (user: any) => {
    return {
      // 'fdj': {
      //   'condition': user.isUnderEighteen() && user.membership !== null,
      //   'link': "https://www.fdj.fr/inscription/parions-sport-enligne/cac/?xtor=AL-1014-%5BPSEL_PARTENARIAT_OM_ACQ_WEB_GENERIC_INSCRIPTION___%5D-%5BOM%5D&campaign=pbb10&dclid=CIn2yevh54UDFZ9IHQkdH90HEQ",
      //   'header': <img src='https://www.om.fr/sites/default/files/2024-03/logo-psel-1l-2024.png' alt='Parions Sport' />,
      //   'children': t('dashboard.partner.parionssport_pbb'),
      // },
      'ovtrail': {
        'condition': true,
        'link': "https://www.sportsnconnect.com/events/parcours_cart/5796/default2",
        'header': (<div>
          <img src={'https://cdn.sportsnconnect.com/img/uploads/230905112539-9n2oncnLO147Os3ot8s0ec5tGr6p.png'} alt={'Orange Vélodrome Trail'} />
          <div className='title'>Orange Vélodrome Trail</div>
        </div>),
        'children': t('dashboard.partner.ovtrail'),
      },
      'cebe': {
        'condition': true,
        'link': "https://www.cebe.com/fr/?utm_source=sitecebe&utm_medium=promopbb&utm_campaign=encartom",
        'header': <img src={'https://www.om.fr/sites/default/files/2024-04/CEBE-Logo.png'} alt={'Cébé'} />,
        'children': t('dashboard.partner.cebe'),
      },
      'soleio': {
        'condition': true,
        'link': "https://file.splio3.fr/7sX/Ou/wAjc",
        'header': <img src={'https://cdn.prod.website-files.com/64d087c18a483be17277f8f0/6516d7262311232aeef702e5_LOGO%20SOLEIO%20VERSION%20FINALE%2002%2012.svg'} alt={'Cébé'} />,
        'children': t('dashboard.partner.soleio'),
      },
      'laprovence': {
        'condition': true,
        'link': "https://abonnement.laprovence.com/abonnement/offre-numerique-mensuelle-abonnes-om?at_medium=display&at_campaign=partenariatOM&at_channel=siteOM&at_format=banniere",
        'header': <img src={'https://www.laprovence.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-lp.45279693.png&w=1080&q=75'} alt={'La Provence'} />,
        'children': t('dashboard.partner.laprovence'),
      },
    };
  }

  useEffect(() => {
    if (!!user?.ticketingID) {
      qrCode.append(ref.current);
      qrCode.update({ data: `${user?.ticketingID}` });

      const svg = document.getElementById("qr-container")?.querySelector("svg");
      svg?.setAttribute("viewBox", "0 0 250 250");
      svg?.setAttribute("preserveAspectRatio", "xMidYMid meet");
      if (!!svg) {
        svg.style.width = "100%";
      }
    }
    setPartnerCount(document.querySelectorAll('.membership .partners .partner').length as any)
    setClubOfferCount(document.querySelectorAll('.membership .club-offer .partner').length as any)
  }, []);

  const displayContent = (e: any) => {
    e.stopPropagation()
    e.target.classList.toggle('display')
  }

  if (!user || !user.membership) {
    return <>
      {/* Member Card */}
      <div className='membership-subscribe'>
        <div className='card bloc'>
          <Card membership={null} />
          <Button type={ButtonType.Link} isTargetblank={true} href='https://membres.om.fr'>{t('membership.button')}</Button>
        </div>
      </div>
    </>
  }

  const advantages: any = getAdvantages();
  const partners: any = getPartners(user);

  return <>
    <div className='mobile decoration'>
      <div className='border'>
        <div className='dark-blue-border'></div>
        <div className='primary-border'></div>
      </div>
    </div>

    <div className='membership'>
      <div className='title-header'>
        <h1>
          {user?.membership?.type === Membership_Type.om_legendes ? t('om_legends.title') : t('membership.title')}
          <div className='desktop'><Button type={ButtonType.Primary} onClick={clickWallet} className='small'>{ t('membership.add_ecard') }</Button></div>
        </h1>
      </div>

      <div className='membership-content'>
        {/* QR Code */}
        <div className='mobile card accordion card-column' onClick={(e) => displayContent(e)}>
          <div className='header mobile'>{t('membership.ecard')}</div>
          <div className='content'>
            <div className='member-account-info'>
              <div className='subheader'>{t('membership.qr_info')}</div>
              <div className='qrcode'>
                <div className='frame'><div id="qr-container" ref={ref} /></div>
              </div>
              <Button type={ButtonType.Primary} onClick={clickWallet}><Icon type={IconType.Wallet} fill='white' /> {t('membership.button_wallet')}</Button>
            </div>
          </div>
        </div>

        {/* Advantages */}
        <div className='membership-block club-offer'>
          <div className='block-title'>
            <h2>{t('membership.club-advantages')}</h2>
            <div className='circle'></div>
            <div className='block-count'><span>{t('membership.total')}</span><span>{clubOfferCount}</span></div>
          </div>

          <Carousel>
            {Object.keys(advantages).map((key) => {
              const obj = advantages[key];
              return <PartnerBloc
                link={obj.link}
                header={<div className='block-header'>
                  <Icon type={obj.icon} fill='#0098D8' />
                  <span className='title'>{obj.title}</span>
                </div>}
                children={obj.children}
              />;
            })}
          </Carousel>
        </div>

        {/* Evénements */}
        {/* TODO */}
        {/* Non actif pour le moment */}
        {/* <div className='membership-block'>
          <h2>{t('membership.events')}</h2>
          <Carousel>
            <PromoEvent />
          </Carousel>
        </div> */}

        {/* Partners */}
        <div className='membership-block partners'>
          <div className='block-title'>
            <h2>{t('membership.partners')}</h2>
            <div className='circle'></div>
            <div className='block-count'><span>{t('membership.total')}</span><span>{partnerCount}</span></div>
          </div>
          <Carousel>
            {Object.keys(partners).map((key) => {
              const ad = partners[key];
              return ad.condition ? <PartnerBloc
                link={ad.link}
                header={ad.header}
                children={ad.children}
              /> : null;
            })}
          </Carousel>
        </div>

        {/* Links */}
        <div className='links'>
          <div className="bloc-link">
            <div className="link" onClick={(e) => { e.preventDefault(); window.open('https://www.om.fr/fr/news?type=All&field_theme_target_id=5915&field_accessibility_target_id=All', '_blank', 'noreferrer') }}>
              <Icon type={IconType.Bell} fill={"#0098D8"} />
              <span>{t('membership.actuality')}</span>
            </div>
          </div>
          <div className="bloc-link">
            <div className="link" onClick={(e) => { e.preventDefault(); window.open('https://membres.om.fr/', '_blank', 'noreferrer') }}>
              <Icon type={IconType.Member} fill={"#0098D8"} />
              <span>{t('membership.pbb_website')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default Member;
